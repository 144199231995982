import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';
import Logo from "../../assets/small_logo.png"
import { Link } from 'gatsby';
const Header = () => (  
  <header className="sticky top-0 bg-white shadow">
     
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8" >
      
      <div className="flex items-center text-2xl" >
       <AnchorLink href="#home">
        <div className="w-12 mr-3">
           <img src={Logo} alt="A dog smiling in a party hat" />
        </div>
        </AnchorLink>
        Infinity
      </div>

      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#features">
          Services
        </AnchorLink>
        <AnchorLink className="px-4" href="#services">
          Why Us
        </AnchorLink>
        <AnchorLink className="px-4" href="#stats">
          Stats
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimonials">
          Testimonials
        </AnchorLink>
      </div>
      <div className="hidden md:block">
       <AnchorLink href="#contact_us">
        <Button className="text-sm" >Contact Us</Button>
       </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
