import React from 'react';
  
const Footer = () => (
  <footer className="container mx-4 py-8 px-3 mt-2 mb-8 text-gray-800">
    <div className="flex mx-2">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">Infinity Techniques is a Top Mobile App Development based out of Ludhiana, India.</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://firebasestorage.googleapis.com/v0/b/just-laugh-d177c.appspot.com/o/website%2Fjust_laugh_privacy.html?alt=media&token=5cb584c6-7f8c-4ecb-aed2-206b79e5ec09">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="https://firebasestorage.googleapis.com/v0/b/just-laugh-d177c.appspot.com/o/website%2Fjust_laugh_privacy.html?alt=media&token=5cb584c6-7f8c-4ecb-aed2-206b79e5ec09">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.facebook.com/infinitytechniques/">Facebook</a>
          </li>
          <li>
            <a href="https://www.upwork.com/o/companies/~01f80c18ed84bde0ad">Upwork</a>
          </li>
         
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
